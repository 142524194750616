import request from "@/utils/request";

export function list(params) {
  return request({
    url: "/omp/couponPackage/list",
    method: "GET",
    params,
  });
}

export function add(data) {
  return request({
    url: "/omp/couponPackage/add",
    method: "POST",
    data,
  });
}

export function deleteCoupon(params) {
  return request({
    url: "/omp/couponPackage/del",
    method: "DELETE",
    params,
  });
}

export function endCoupon(params) {
  return request({
    url: "/omp/couponPackage/end",
    method: "PUT",
    params,
  });
}

export function updateCoupon(data) {
  return request({
    url: "/omp/couponPackage/upd",
    method: "PUT",
    data,
  });
}
